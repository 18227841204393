import isEmpty from 'lodash/isEmpty';
import { BREAKPOINT_MAP } from 'consts';
import { getImageDataFromWpResponse, parseCTA } from 'utils/transformHelpers';

export function getBlockCount(blockCount) {
  const propertyMap = {};

  Object.values(BREAKPOINT_MAP).forEach((width) => {
    propertyMap[width.label] = '';
  });

  if (isEmpty(blockCount)) {
    return propertyMap;
  }

  Object.keys(blockCount).forEach((block) => {
    const currentBlock = blockCount[block];
    propertyMap[block] = currentBlock;
  });

  return propertyMap;
}

export default function wpToFeatureBlocksTransform(acfValues) {
  return {
    blocks: (acfValues.featureBlocks || []).map((block) => {
      const {
        callToAction,
        description,
        featureImage,
        title,
        wrapBlockWithCtaLink = true,
      } = block;

      return {
        cta: parseCTA(callToAction),
        featureImage: getImageDataFromWpResponse({ image: featureImage }),
        title: title || '',
        description: description || '',
        wrapBlockWithCtaLink,
      };
    }),
    customCssClass: acfValues.customCssClass || '',
    sectionTitle: acfValues.title || '',
    subTitle: acfValues.subTitle || '',
    content: acfValues.content || '',
    variation: acfValues.variation || '',
    background: acfValues.background || '',
    blockCount: getBlockCount(acfValues.blockCount),
    mediaWidth: acfValues.mediaWidth || '',
    blockAlignment: acfValues.blockAlignment || '',
  };
}

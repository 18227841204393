/**
 * @module Logging
 * @summary The key function to log errors and key events to our dashboard will be here
 * @see LogError
 * @see LogInfo
 */
import { TrackJS } from './trackjs-isomorphic';
import isBrowser from './isBrowser';

const TRIGGERED_ERROR_MAP = {};

let newrelic;

/**
 * @summary use this to add meta data to all errors
 */
export function addErrorTrackingMeta(key, value) {
  try {
    if (TrackJS.isInstalled()) {
      if (isBrowser()) {
        TrackJS.addMetadata(key, value);
      } else {
        TrackJS.addLogTelemetry('info', `addMeta ran ${key}=${value}`, {
          [key]: value,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * @summary Initialize Tracking Configs for either Server or Browser
 * @param {String} trackingId - The TrackJs ID
 * @param {Object} opts - other config values to pass with errors for more context
 */
export function InitializeLogging(trackingId, opts = {}) {
  const { configEnv, buildId, revisionId, userId = '' } = opts;
  const application = `triadms_${configEnv}`;
  let isConfigSuccess = false;

  // show environments in console on non-prod environments
  if (isBrowser() && opts.configEnv !== 'production') {
    console.table({
      env: opts.configEnv,
      BE: opts.triadBackendDomain,
      WP: opts.WPHost,
    });
  }

  if (!TrackJS.isInstalled()) {
    TrackJS.install({
      token: trackingId,
      application,
      console: {
        watch: ['info', 'warn', 'error'],
        display: configEnv !== 'production',
      },
      version: revisionId,
    });

    // Note this may be Nodejs Or Browser API, both return bool as per specs
    isConfigSuccess = TrackJS.configure({ userId });

    // This will be attached to every error
    TrackJS.addMetadata('configEnv', configEnv);
    TrackJS.addMetadata('buildId', buildId);

    if (isBrowser()) {
      TrackJS.addMetadata('domain', window.location.host);
      TrackJS.addMetadata('asPath', window.location.pathname);

      if (window.navigator) {
        const connection =
          navigator.connection ||
          navigator.mozConnection ||
          navigator.webkitConnection;
        TrackJS.addMetadata('connection', connection || 'Not Found');
        TrackJS.addMetadata('cookieEnabled', navigator.cookieEnabled);
        TrackJS.addMetadata('languages', navigator.languages);
      }

      try {
        const plugins = Object.keys(window.navigator.plugins).map((index) => {
          const plugin = window.navigator.plugins[index];
          return plugin.name || plugin.description || plugin.filename;
        });
        TrackJS.addMetadata('plugins', plugins);
      } catch (error) {
        TrackJS.addMetadata('plugins', ['ERROR', error.message]);
      }
    }
  }

  // already installed
  return isConfigSuccess;
}

/**
 * @summary Will return true if the error has been triggered already on the user's session.
 *          If not it will keep track of this new error.
 * @param {String | Error} error - The error we are looking to record
 */
function _hasAlreadyLoggedThisSession(error) {
  try {
    const message = error.toString();
    if (!isBrowser()) {
      return false;
    }

    if (TRIGGERED_ERROR_MAP[message]) {
      return true;
    }

    TRIGGERED_ERROR_MAP[message] = true;
    return false;
  } catch (err) {
    return false;
  }
}

/**
 * @summary The main error tracking function
 * @param {String | Error} error - The error we are looking to record
 */
export function LogError(error, meta = {}) {
  if (_hasAlreadyLoggedThisSession(error)) {
    return;
  }

  if (newrelic) {
    newrelic.noticeError(error, meta);
  }

  if (TrackJS.isInstalled()) {
    Object.keys(meta).forEach((key) => {
      TrackJS.addMetadata(key, meta[key]);
    });

    TrackJS.track(error);

    // Removing as to not let meta values bleed into other errors
    Object.keys(meta).forEach((key) => {
      TrackJS.removeMetadata(key);
    });
  }
}

/**
 * @summary Set the user ID for tracking
 * @param {Object} sessionData - sessionId, schoolCode, googleTagManagerSiteId, floodLightActivityFilters, isPersonalized, geoLocation, developmentEnvironment
 */
let sessionDataForLogging = {};
export function configureSessionForLogging(sessionData = {}) {
  if (newrelic?.setUserId && sessionData.sessionId) {
    newrelic.setUserId(sessionData.sessionId);
  }

  sessionDataForLogging = { ...sessionDataForLogging, ...sessionData };
}

// TODO [T1-11488]: create unit test for this. Make sure to feed it object directly from getNewSessionCall so we have parsed Object
/**
 * @summary Track a page view
 * @deprecated use Log Function instead
 * @param {Object} attributes - The attributes to track with the page view
 * @see https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/using-browser-apis/
 */
export function newrelicPageAction(actionName, attributes = {}) {
  try {
    let gtmDataLayer;

    try {
      gtmDataLayer =
        window.google_tag_manager[sessionDataForLogging.googleTagManagerSiteId]
          .dataLayer;
    } catch (error) {
      gtmDataLayer = { get: () => {} };
    }

    const filterValues = sessionDataForLogging.floodLightActivityFilters || {};

    const globalAttributes = {
      ...attributes,
      gtmOriginSchoolCode: gtmDataLayer.get('origin_school_code'),
      fvTrackingSchoolCode: filterValues.trackingSchoolCode,
      fvPropertyOrigin: filterValues.propertyOrigin,
      fvIsClickUser: filterValues.isClickUser,
      fvCampaignType: filterValues.campaignType,
      fvAdPlatformSource: filterValues.adPlatformSource,
      schoolCode: sessionDataForLogging.schoolCode,
      developmentEnvironment: sessionDataForLogging.developmentEnvironment,
      gclid: sessionDataForLogging.searchEngineCookies?.gclid,
      msclkid: sessionDataForLogging.searchEngineCookies?.msclkid,
      sid: sessionDataForLogging.sid,
    };
    newrelic?.addPageAction(actionName, globalAttributes);
  } catch (error) {
    console.error('newrelicPageAction', error);
  }
}

const ERROR_MESSAGE_TO_IGNORE = [
  'Script error',
  'Unhandled Promise Rejection: Object Not Found Matching Id:',
  'Request has been terminated Possible causes',
];

/**
/**
 * Determines whether an error should be tracked.
 *
 * @param {Error} error - The error to check.
 * @return {boolean}
 */
export function shouldTrackError(error) {
  for (let i = 0; i < ERROR_MESSAGE_TO_IGNORE.length; i += 1) {
    if (error.message.includes(ERROR_MESSAGE_TO_IGNORE[i])) {
      return false;
    }
  }

  return true;
}

/**
 * Logs a message to New Relic with the specified level and custom attributes.
 *
 * @param {string} message - The message to log.
 * @param {string} level - The log level.
 * @param {object} meta - Custom attributes to include with the log message.
 * @return {void}
 */
function newRelicLogger(message, level, meta = {}) {
  const metaData = {
    description: meta.description,
    endpoint: meta.endpoint,
    'tms.app': 'microsite-platform',
    'entity.id': sessionDataForLogging.sessionId,
    environment: sessionDataForLogging.developmentEnvironment,
    schoolCode: sessionDataForLogging.schoolCode,
    sid: sessionDataForLogging.sid,
    applicationBuildId: sessionDataForLogging.environmentVariables?.buildId,
    triadBackendDomain:
      sessionDataForLogging.environmentVariables?.triadBackendDomain,
  };

  if (newrelic && isBrowser()) {
    newrelic?.log(message, {
      level,
      customAttributes: metaData,
    });
  }

  if (newrelic && !isBrowser()) {
    // TODO [FSIU-65]: Add support for server logging to new relic
  }
}

/**
 * Logs a warning message with custom attributes.
 *
 * @param {string} message - The warning message to log.
 * @param {object} meta - Custom attributes to include with the log message.
 * @return {void}
 */
export function LogWarning(message, meta = {}) {
  newRelicLogger(message, 'warn', meta);
}

/**
 * Logs a debug message with custom attributes.
 *
 * @param {string} message - The debug message to log.
 * @param {object} meta - Custom attributes to include with the log message.
 * @return {void}
 */
export function LogDebug(message, meta = {}) {
  newRelicLogger(message, 'debug', meta);
}

/**
 * @summary This is only to be used for logging information, such as key events.
 * @param {String} message - The message to record
 * @param {Object} meta - extra key value pairs to record with this log
 */
export function LogInfo(message, meta = {}) {
  newRelicLogger(message, 'info', meta);
  if (TrackJS.isInstalled()) {
    if (isBrowser()) {
      window.console.info(`${message}. metaData=${JSON.stringify(meta)}`);
    } else {
      TrackJS.addLogTelemetry('info', message, meta);
    }
  }
}

import('utils/newrelic-isomorphic')
  .then((module) => {
    newrelic = module.default;
    newrelic?.setErrorHandler((err) => {
      // Returning false will always track this error
      return !shouldTrackError(err);
    });
  })
  .catch((error) => {
    LogError('Error loading newrelic', { error: error.message });
  });

import React, { useRef, useEffect, useContext } from 'react';

import GlobalContext from 'hooks/contexts/GlobalContext';

function ModalContainer() {
  const modalContainerRef = useRef(null);
  const { actions } = useContext(GlobalContext);

  useEffect(() => {
    actions.updateGlobalRefs({
      modalContainerRef,
    });
    return () => {
      actions.updateGlobalRefs({
        modalContainerRef: null,
      });
    };
  }, []);

  return <div id="modalContainer" ref={modalContainerRef} />;
}

export default ModalContainer;

/* eslint-disable import/prefer-default-export */

export function getImageDataFromWpResponse(images = {}) {
  const imageObj = {};

  Object.keys(images).forEach((image) => {
    const currentImg = images[image];

    if (!currentImg) {
      return;
    }
    imageObj[image] = {
      url: currentImg.url,
      mimeType: currentImg.mime_type,
      sizes: currentImg.sizes || {},
      srcSet: currentImg.srcset || '',
      width: currentImg.width || '',
      height: currentImg.height || '',
      alt: currentImg.alt || '',
      svg: currentImg.svg || '',
    };
  });

  return imageObj;
}

export function getCTALink(cta) {
  switch (cta.buttonActionType) {
    case 'pageLink':
      return cta.pageLink;

    case 'customLink':
      return cta.customLink;

    default:
      return '';
  }
}

export function parseCTA(cta) {
  if (!cta) {
    return {};
  }
  return {
    useCTA: cta.useCTA || false,
    buttonText: cta.buttonText || '',
    buttonActionType: cta.buttonActionType || '',
    link: getCTALink(cta),
  };
}

import { getImageDataFromWpResponse, parseCTA } from 'utils/transformHelpers';

function parseSlides(slides) {
  return slides.map((slide) => {
    return {
      images: getImageDataFromWpResponse(slide.images),
      title: slide.title || '',
      subTitle: slide.subTitle || '',
      content: slide.content || '',
      cta: parseCTA(slide.callToAction),
      wysiwyg: slide.wysiwyg || '',
      videoEmbedId: slide.videoEmbedId || '',
    };
  });
}

export default function wpToSliderTransform(acfValues) {
  return {
    title: acfValues.title || '',
    subTitle: acfValues.subTitle || '',
    imageAlignment: acfValues.imageAlignment || '',
    customCssClass: acfValues.customCssClass || '',
    variation: acfValues.variation || '',
    background: acfValues.background || '',
    isReversed: acfValues.isReversed || false,
    mediaWidth: acfValues.mediaWidth || '',
    slides: parseSlides(acfValues.slides),
  };
}

export default function wpToUserContentTransform(acfValues) {
  return {
    background: acfValues.background || '',
    variation: acfValues.variation || '',
    content: acfValues.content || '',
    customCssClass: acfValues.customCssClass || '',
    isReversed: acfValues.isReversed || '',
    subTitle: acfValues.subTitle || '',
    textAlignment: acfValues.textAlignment || '',
    title: acfValues.title || '',
  };
}

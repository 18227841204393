import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getFutureStartDates } from 'utils/startDateHelpers';

export function getFormats(isCampus, isOnline) {
  const formats = [];

  if (isCampus) {
    formats.push('Campus');
  }

  if (isOnline) {
    formats.push('Online');
  }
  return formats.join(' & ');
}

export function getAssociatedProgramData(programData) {
  if (isEmpty(programData)) {
    return {};
  }
  const {
    name,
    longDescription,
    startDates,
    totalCreditsRequired,
    isCampus,
    isOnline,
    onlineLengthInWeeks,
    maxTransferCredits,
    degreeTypeGuids,
  } = programData;

  return {
    title: name || '',
    description: longDescription || '',
    startDates: getFutureStartDates(startDates) || [],
    totalCreditsRequired: totalCreditsRequired || '',
    format: getFormats(isCampus, isOnline),
    courseLength: onlineLengthInWeeks || '',
    transferCredits: maxTransferCredits || '',
    degreeTypeGuids: degreeTypeGuids || [],
  };
}

export default function wpToProgramInfoTransform(acfValues, metaData) {
  return {
    associatedProgram: getAssociatedProgramData(
      get(metaData, 'pageLayoutInfo.acf.associatedProgram', {})
    ),
    title: acfValues.title || '',
    subTitle: acfValues.subTitle || '',
    content: acfValues.content || '',
    cta: acfValues.callToAction || {},
    customCssClass: acfValues.customCssClass || '',
    variation: acfValues.variation || '',
    background: acfValues.background || '',
    isReversed: acfValues.isReversed || false,
    mediaWidth: acfValues.mediaWidth || '',
  };
}

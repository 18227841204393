import isFinite from 'lodash/isFinite';

export function capitalizeFirstLetter(string) {
  if (!string) {
    return '';
  }
  return string[0].toUpperCase() + string.slice(1);
}

export function isNumber(value) {
  return isFinite(parseInt(value, 10));
}

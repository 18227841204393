import { AB_TEST_TYPE_MAP } from 'consts';
import request from 'utils/request';

const { QUESTIONNAIRE } = AB_TEST_TYPE_MAP;

export default async function processTestRequest(payload, siteMeta) {
  const testType = AB_TEST_TYPE_MAP[payload.type];

  // window.activateAbTest({ type: 'QUESTIONNAIRE', variant: 'V2'})
  if (testType === QUESTIONNAIRE) {
    const questionnaire = await request({
      method: 'post',
      url: '/api/triadms/ab-test',
      body: {
        variant: payload.variant,
        schoolCode: siteMeta.schoolCode,
        type: QUESTIONNAIRE,
      },
    }).catch(() => null);

    return {
      [QUESTIONNAIRE]: questionnaire,
    };
  }

  return {};
}

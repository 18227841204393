import { SKIP_REASON_CODES } from 'consts';
import get from 'lodash/get';
import { BasicFormWizardContext, Question } from 'types';

/**
 * @summary its possible some form questions are answered and must be skipped over when user gets to them
 * @param {Array} questions - an array of questions
 * @param {Object} formState - the formState that useBasicFormWizardHandler produces
 */
export default function getVisibleQuestions(
  questions: Question[],
  formState: BasicFormWizardContext
): Question[] {
  return questions.filter(({ name }) => {
    const isSkippedQuestion =
      get(formState, `formValues[${name}].shouldSkip`) ||
      get(formState, `formValues[${name}][0].shouldSkip`);

    const skipReason =
      get(formState, `formValues[${name}].skipReason`) ||
      get(formState, `formValues[${name}][0].skipReason`);

    const isFromOtherSite = get(
      formState,
      `formStatus.linkedSessionFormValues[${name}].isFromOtherSite`
    );

    if (isSkippedQuestion && skipReason === SKIP_REASON_CODES.USER_SKIPPED) {
      return true;
    }

    if (isSkippedQuestion) {
      return false;
    }

    if (isFromOtherSite) {
      return false;
    }

    return true;
  });
}

import { getImageDataFromWpResponse, parseCTA } from 'utils/transformHelpers';
import { BREAKPOINT_MAP } from 'consts';

export function getFontSizeAdjustments({
  titleTextSizeAdjustment = 0,
  titleFontAdjustment,
  subtitleFontAdjustment,
  useTitleFontOverrides,
  useSubtitleFontOverrides,
}) {
  // legacy `titleTextSizeAdjustment` value should be used unless an override is being used for either title or subtitle
  const defaultSize =
    useTitleFontOverrides || useSubtitleFontOverrides
      ? 0
      : titleTextSizeAdjustment;

  const adjustments = {
    titleFontAdjustment: {
      [BREAKPOINT_MAP.MOBILE.label]: defaultSize,
      [BREAKPOINT_MAP.TABLET.label]: defaultSize,
      [BREAKPOINT_MAP.DESKTOP.label]: defaultSize,
    },
    subtitleFontAdjustment: {
      [BREAKPOINT_MAP.MOBILE.label]: defaultSize,
      [BREAKPOINT_MAP.TABLET.label]: defaultSize,
      [BREAKPOINT_MAP.DESKTOP.label]: defaultSize,
    },
  };

  if (useTitleFontOverrides && titleFontAdjustment?.fontSizeAdjustments) {
    Object.entries(titleFontAdjustment.fontSizeAdjustments).forEach(
      ([size, value]) => {
        adjustments.titleFontAdjustment[size] = value;
      }
    );
  }

  if (useSubtitleFontOverrides && subtitleFontAdjustment?.fontSizeAdjustments) {
    Object.entries(subtitleFontAdjustment.fontSizeAdjustments).forEach(
      ([size, value]) => {
        adjustments.subtitleFontAdjustment[size] = value;
      }
    );
  }
  return adjustments;
}

export default function wpToHeroPropsTransform(acfValues) {
  const heroProps = {
    background: acfValues.background || '',
    defaultTitleSize: acfValues.titleTextSizeAdjustment || '',
    variation: acfValues.variation || '',
    images: getImageDataFromWpResponse(acfValues.images),
    mobileImage: acfValues.images.mobileImage || {},
    heroType: acfValues.heroType || '',
    content: acfValues.content || '',
    subTitle: acfValues.subTitle || '',
    title: acfValues.title || '',
    cta: parseCTA(acfValues.callToAction),
    customCssClass: acfValues.customCssClass || '',
  };

  return { ...heroProps, ...getFontSizeAdjustments(acfValues) };
}

/**
 * ONLY USE ON THE SERVER. These constants are directly imported from configs file that is only loaded on the server
 * If you need access to any configs on the client side we need to take it from global context as the server passes
 * the configs to global context on hydration.
 */
import configs from 'configs';

export const TRIADMS_BACKEND = configs.triadBackendDomain;
export const TRIAD_PROXY_ROUTE = '/api/triadms';

export const API_URL = '/wp-json/wp/v2';
export const WP_API_URL = '/wp-json/triad/v1';

export const IS_DEBUG_ENABLED = configs.isDebugEnabled;

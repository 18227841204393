import { LogError } from './logging';

const EVENTS_MAP = {
  GENERIC_EVENT: 'ga_generic_event',
  GENERIC_EVENT_COUNTER: 'generic_event_counter',
};

function gaEvent(eventObj) {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventObj);
  } catch (error) {
    LogError(`Failed to trigger GA tracking: ${error.message}`);
  }
}

export const COUNTERS = {
  USING_MEM_COOKIE: 'using_mem_cookie',
  NO_SESSION_SUBMIT: 'no_session_submit',
  UNESCAPE_COOKIE: 'unescape_cookie',
  SESSION_STORAGE_DISABLED: 'session_storage_disabled',
  CURRENT_STEP_ADVANCED: 'current_step_advanced',
  FLOODLIGHT_TRACKING: 'floodlight_tracking',
};

/**
 * @summary trigger an event to be used as a counter
 * @param {String} counterName String the name of a counter. Say we wanted to count how often a button triggered an error counter name can be like more_info_btn_error
 * @param {String} opts.label a label to be used for the user action that triggered the counter
 * @param {String} opts.schoolCode the school code of the school the user is interacting with
 * @param {String} opts.category category to be used to further categorize the counter
 * @param {String} opts.tags tags to be used to further categorize the counter
 */
const triggeredCounters = {};
export function incrementCounter(counterName, opts = {}) {
  const { label, schoolCode, category, tags, deDup = true, sessionId } = opts;

  if (deDup && triggeredCounters[counterName]) {
    return;
  }

  triggeredCounters[counterName] = true;

  const trackingObject = {
    event: EVENTS_MAP.GENERIC_EVENT,
    ga_event_name: EVENTS_MAP.GENERIC_EVENT_COUNTER,
    action: counterName,
  };

  if (sessionId) {
    // Note: sessionID is not camelCase to match the GA mapping
    trackingObject.sessionID = sessionId;
  }

  if (label) {
    trackingObject.label = label;
  }

  if (schoolCode) {
    trackingObject.school_code = schoolCode;
  }

  if (category) {
    trackingObject.category = category;
  }

  if (tags) {
    trackingObject.tags = tags;
  }

  gaEvent(trackingObject);
}

import { useEffect } from 'react';
import { NOP } from 'utils/generalUtils';

/**
 *
 * @param {*} ref
 * @param {any} callback
 */
const useOutsideClick = (ref, callback = NOP) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      // TODO:  edge case where React Select renders outside the container and makes form selections seem like they're outside the current element
      // remove if we ever get rid of React Select
      if (
        e.target.className.includes &&
        e.target.className.includes('select__')
      ) {
        return;
      }

      callback(ref, 'click');
    }
  };

  const handleEscKey = (e) => {
    if (e.key === 'Escape' && callback) {
      callback(ref, 'esc');
    }
  };

  useEffect(() => {
    if (!ref || !ref.current) {
      return () => {};
    }

    document.addEventListener('click', handleClick);
    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [ref, callback]);
};

export default useOutsideClick;

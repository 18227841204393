import { getImageDataFromWpResponse } from 'utils/transformHelpers';

export default function wpToAdmissionsTransform(acfValues) {
  return {
    background: acfValues.background || '',
    content: acfValues.content || '',
    customCssClass: acfValues.customCssClass || '',
    datesLabel: acfValues.datesLabel || '',
    imageAlignment: acfValues.imageAlignment || '',
    images: getImageDataFromWpResponse(acfValues.images),
    isReversed: acfValues.isReversed || false,
    mediaWidth: acfValues.mediaWidth || '',
    subTitle: acfValues.subTitle || '',
    title: acfValues.title || '',
    variation: acfValues.variation || '',
  };
}

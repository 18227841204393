import { getCookie } from './analyticsHelpers';

const TRAFFIC_SOURCES = {
  GOOGLE: 'google',
  BING: 'bing',
  UNKNOWN: 'unknown',
};

export function getSearchEngineCookies(
  urlQueryParams = window.location.search
) {
  try {
    const urlParams = new URLSearchParams(urlQueryParams);
    const gclid = urlParams.get('gclid') || getCookie('gclid');
    const msclkid = urlParams.get('msclkid') || getCookie('msclkid');

    return { gclid, msclkid };
  } catch (error) {
    return { gclid: '', msclkid: '' };
  }
}

export function getTrafficSource(urlQueryParams) {
  try {
    const { gclid, msclkid } = getSearchEngineCookies(urlQueryParams);

    // its possible MS ads have gclid
    if (!msclkid && gclid) {
      return TRAFFIC_SOURCES.GOOGLE;
    }

    if (msclkid) {
      return TRAFFIC_SOURCES.BING;
    }
    return TRAFFIC_SOURCES.UNKNOWN;
  } catch (error) {
    return TRAFFIC_SOURCES.UNKNOWN;
  }
}

export function isTrafficSourceGoogle() {
  return getTrafficSource() === TRAFFIC_SOURCES.GOOGLE;
}

import { initialState } from 'hooks/reducers/GlobalReducer';
import React from 'react';

const globalContext = {
  // All Available Taxonomy Values for the site
  microSiteTaxonomyMap: null,
  sessionInfo: initialState.sessionInfo,
  questionnaire: {},
  // Page Level Taxonomy Values
  taxonomyValues: {
    degrees: [],
    parentCategories: [],
    categories: [],
  },
  flags: initialState.flags,
  userData: initialState.userData,
  refs: {
    heroRef: null,
    headerRef: null,
    modalContainerRef: null,
    clickPortalSearchResultsRef: null,
    themeWrapperRef: null,
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    updateGlobalFlags: (flags) => null,
  },
  windowSize: initialState.windowSize,
  clickPortal: initialState.clickPortal,
  formSettings: initialState.formSettings,
  microPortal: initialState.microPortal,
  siteMeta: { disclaimerSuccessText: '', floodlightActivityValues: {} },
};

const GlobalContext = React.createContext(globalContext);

export default GlobalContext;

import camelCase from 'lodash/camelCase';
import uniqBy from 'lodash/uniqBy';
import find from 'lodash/find';
import { isFuture, getTime } from 'date-fns';

export function getFutureStartDates(startDates) {
  if (!startDates) {
    return [];
  }
  let futureStartDatesArray = [];
  startDates.forEach((startDate) => {
    const currentStartDate = getTime(new Date(startDate.startDate));

    // only include dates that are in the future
    if (isFuture(currentStartDate)) {
      futureStartDatesArray.push({
        startDateInMilliseconds: currentStartDate,
        startDate: startDate.startDate,
        registerBy: startDate.registerBy,
      });
    }
  });

  // remove duplicate dates
  futureStartDatesArray = uniqBy(
    futureStartDatesArray,
    'startDateInMilliseconds'
  );

  // sort ascending
  futureStartDatesArray.sort(
    (a, b) => a.startDateInMilliseconds - b.startDateInMilliseconds
  );

  return futureStartDatesArray;
}

export function filterStartDates(startDatesArray = []) {
  const futureStartDates = {};
  if (!startDatesArray) {
    return {};
  }
  startDatesArray.forEach((startDateType) => {
    futureStartDates[camelCase(startDateType.label)] = {};

    if (!startDateType.startDates || !startDateType.startDates.length) {
      return;
    }

    futureStartDates[camelCase(startDateType.label)] = {
      label: startDateType.label,
      degreeType: startDateType.degreeType || '',
      startDates: getFutureStartDates(startDateType.startDates),
    };
  });

  return futureStartDates;
}

// if there are not startDates configured on the program, then fallback to the global startDates and filter by degreeType
export function getProgramStartDates(
  programStartDates,
  schoolStartDates,
  programDegreeTypeGuid
) {
  if (programStartDates && programStartDates.length) {
    return programStartDates;
  }

  const fallbackStartDates = find(schoolStartDates, (sd) => {
    return programDegreeTypeGuid.includes(sd.degreeType);
  });

  return fallbackStartDates ? fallbackStartDates.startDates : [];
}

export function getDayOfWeekName(date) {
  if (!date) {
    return '';
  }
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const dayOfWeek = date.getUTCDay();
  return days[dayOfWeek];
}

export function getMonthName(date) {
  if (!date) {
    return '';
  }
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthIndex = date.getUTCMonth();
  return months[monthIndex];
}

/* eslint-disable import/prefer-default-export */
import get from 'lodash/get';

/**
 * @summary utility function to extract school logo
 * @private
 */
export function getSchoolLogoUrlMap(images) {
  const defaultImgUrl = '/theme-assets/click-portal/tablet.png';
  const imageMap = {
    mobile:
      get(images, 'Mobile.imageUrl') ||
      get(images, 'Desktop.imageUrl') ||
      defaultImgUrl,
    desktop: get(images, 'Desktop.imageUrl') || defaultImgUrl,
  };

  return imageMap;
}

// Note this should only run on the server, called by getLayouts at the section level
import { PROGRAM_GROUP_NAMES } from 'consts';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';

const { MAIN, RELATED, OTHER } = PROGRAM_GROUP_NAMES;

function hasProgram(type, option, programsList) {
  return programsList.some((program) => program[type].includes(option.value));
}

function getDropdownOptions(options = {}) {
  return {
    degreePlaceholder: options.degreePlaceholder || '-- Please Select --',
    parentCategoryPlaceholder:
      options.parentCategoryPlaceholder || '-- Please Select --',
    parentCategoryLabel:
      options.parentCategoryLabel || 'Browse by Area of Interest',
    degreeLabel: options.degreeLabel || 'Browse by Degree Level',
  };
}

/**
 * @summary parse a single program that comes back at page level to match that in programMap
 */
function parseProgramGroup(groupType, programMap, programObj, wpOrderBy = -1) {
  const programId = programObj.program;

  const fullProgramInfo = find(programMap, ['id', programId]) || {};

  // Note: programMap is derived by getProgramsMeta api call
  const parsedProgram = {
    id: fullProgramInfo.id || '',
    guid: fullProgramInfo.guid || '',
    slug: fullProgramInfo.slug || '',
    title: fullProgramInfo.title || '',
    description: fullProgramInfo.description || '',
    shortDescription: fullProgramInfo.shortDescription || '',
    groupType, // this is what groups on the program module
    degreeLevels: fullProgramInfo.degreeLevels || [],
    parentCategory: fullProgramInfo.parentCategory || [],
    category: fullProgramInfo.category || [],
    isExpanded: programObj.expanded || false,
    isFeatured: programObj.featured || false,
    accreditation: fullProgramInfo.accreditation,
    nextStartDate: fullProgramInfo.nextStartDate,
    orderBy: fullProgramInfo.orderBy,
    infoStats: fullProgramInfo.infoStats,
    wpOrderBy,
  };

  return parsedProgram;
}

function filterInactive(listOfPrograms, programs) {
  // Bail early if WP returns false instead of an array
  if (!listOfPrograms) {
    return [];
  }
  return listOfPrograms.filter(({ program }) => {
    return find(programs, ['id', program]);
  });
}

export default function wpToProgramSearchTransform(wpValues, metaData) {
  const {
    pageLayoutInfo,
    taxonomyMap: { programs, degreeTypes, parentCategories },
  } = metaData;

  // flatten the programs so we can page and use it through other components
  const flatProgramsList = [];

  const { mainProgramList, relatedProgramList, otherProgramList } =
    pageLayoutInfo.acf;

  const _mainProgramList = filterInactive(mainProgramList, programs);
  const _otherProgramList = filterInactive(otherProgramList, programs);
  const _relatedProgramList = filterInactive(relatedProgramList, programs);

  // go through each group and add to array
  (_mainProgramList || []).forEach((programObj, index) => {
    flatProgramsList.push(parseProgramGroup(MAIN, programs, programObj, index));
  });
  (_otherProgramList || []).forEach((programObj, index) => {
    flatProgramsList.push(
      parseProgramGroup(OTHER, programs, programObj, index)
    );
  });
  (_relatedProgramList || []).forEach((programObj, index) => {
    flatProgramsList.push(
      parseProgramGroup(RELATED, programs, programObj, index)
    );
  });

  const degreeOptions = Object.values(degreeTypes)
    .map((degree) => {
      return {
        label: degree.label,
        value: degree.guid,
        featuredPrograms: degree.featuredPrograms,
        orderBy: degree.orderBy,
      };
    })
    .filter((degree) => hasProgram('degreeLevels', degree, programs));

  const parentCategoryOptions = Object.values(parentCategories)
    .map((parentCat) => ({
      label: parentCat.label,
      value: parentCat.guid,
      featuredPrograms: parentCat.featuredPrograms,
      degreeFeaturedPrograms: parentCat.degreeFeaturedPrograms,
      orderBy: parentCat.orderBy,
    }))
    .filter((parentCategory) =>
      hasProgram('parentCategory', parentCategory, programs)
    );

  return {
    title: wpValues.title,
    description: wpValues.description,
    background: wpValues.background || '',
    variation: wpValues.variation || '',
    customCssClass: wpValues.customCssClass || '',
    initialValues: orderBy(flatProgramsList, ['orderBy'], ['asc']),
    // TODO: we have this in global context. In Phase 2 lets remove this from here and have the component tab into global context for this
    fullProgramsList: programs,
    degreeOptions: orderBy(degreeOptions, ['orderBy'], ['asc']),
    paginationLimit: wpValues.paginationLimit || '5',
    parentCategoryOptions: orderBy(parentCategoryOptions, ['orderBy'], ['asc']),
    dropdownOptions: getDropdownOptions(
      wpValues.dropdownOptions || wpValues.dropdownPlaceholders
    ),
    callToActionBehavior: wpValues.callToActionBehavior || '',
    showProgramInfoStats: wpValues.showProgramInfoStats || false,
  };
}

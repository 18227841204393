/* eslint-disable */

import isBrowser from './isBrowser';
import isDevelopment from './isDevelopment';

/**
 *
 * @param {String} gtmSiteId - this is taken from the GTM console, when you create a new site under a workspace.
 */
export function initGtm(gtmSiteId, { schoolCode } = {}) {
  // COPIED FROM GTM CONSOLE (GTM and A/B test Anti Flicker Script)
  return `


    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${gtmSiteId}');
  `;
}

export function initJornaya() {
  if (isDevelopment()) {
    return;
  }

  if (isBrowser() && !window.initJornaya) {
    window.initJornaya = true;
    (function () {
      var s = document.createElement('script');
      s.id = 'LeadiDscript_campaign';
      s.type = 'text/javascript';
      s.async = true;
      s.src =
        '//create.lidstatic.com/campaign/4fac0603-0ffc-4305-97ed-5ae40a1ccb2e.js?snippet_version=2';
      var LeadiDscript = document.getElementById('__next');
      LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
    })();
  }
}

export function initTrustedForm() {
  try {
    if (isDevelopment()) {
      return;
    }

    (function () {
      var field = 'xxTrustedFormCertUrl';
      var provideReferrer = false;
      var invertFieldSensitivity = false;
      var tf = document.createElement('script');
      tf.type = 'text/javascript';
      tf.async = true;
      tf.src =
        'http' +
        ('https:' == document.location.protocol ? 's' : '') +
        '://api.trustedform.com/trustedform.js?provide_referrer=' +
        escape(provideReferrer) +
        '&field=' +
        escape(field) +
        '&l=' +
        new Date().getTime() +
        Math.random() +
        '&invert_field_sensitivity=' +
        invertFieldSensitivity;

      document.body.appendChild(tf);
    })();
  } catch (error) {
    /* silent its ok to fail as per Sung */
  }
}

export function appendGAValue(key, value) {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ [key]: value });
  } catch (error) {
    // Silently Fail
  }
}

/**
 * Retrieves the AB testing API asynchronously.
 *
 * @return {Promise<Object>} A promise that resolves to the AB testing API.
 * @throws {Error} If the server side support is not enabled.
 */
export function getABTestingAPI() {
  // return new Promise((resolve, reject) => {
  //   if (!isBrowser()) {
  //     return reject(new Error('Server Side Support is not enabled'));
  //   }
  //   const checkForGrowthBookInterval = setInterval(() => {
  //     if (window.triadGrowthBookHook) {
  //       clearInterval(checkForGrowthBookInterval);
  //       // expose a limited API
  //       resolve({
  //         setAttributes: window.triadGrowthBookHook.setAttributes,
  //       });
  //     }
  //   }, 500);
  // });
}

import { isSessionStorageEnabled } from 'utils/generalUtils';
import isBrowser from 'utils/isBrowser';
import isDevelopment from 'utils/isDevelopment';

export default class DevController {
  constructor() {
    this._global = isBrowser() ? window : global;
    // Will add server support in the future
    this.isActive = isDevelopment() && isBrowser();
  }

  setSessionStorageValue(key, value) {
    if (!this.isActive || !isSessionStorageEnabled()) {
      return;
    }
    this._global.sessionStorage.setItem(`dev_${key}`, value);
  }

  getSessionStorageValue(key, value) {
    if (!this.isActive || !isSessionStorageEnabled()) {
      return undefined;
    }
    return this._global.sessionStorage.getItem(`dev_${key}`, value);
  }
}

import React, { useContext, useEffect, useRef } from 'react';
import PropType from 'prop-types';
import { useRouter } from 'next/router';
import cx from 'utils/classnames';
import camelCase from 'lodash/camelCase';
import { capitalizeFirstLetter } from 'utils/stringHelpers';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { ROUTE_TO_CONTENT_TYPE_MAP } from 'consts';

function ThemeWrapper({ microSiteTheme = 'default-theme', children, title }) {
  const {
    windowSize,
    flags,
    resumeModal,
    activeModalId,
    cssCustomProps,
    actions,
  } = useContext(GlobalContext);

  const themeWrapperRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    actions.updateGlobalRefs({
      themeWrapperRef,
    });
    return () => {
      actions.updateGlobalRefs({
        themeWrapperRef: null,
      });
    };
  }, []);

  const pageType = ROUTE_TO_CONTENT_TYPE_MAP[router?.route];

  const pageClassName = cx({
    'page--home': !title,
    'page--isLoading': flags.isLoading,
    [`page--${camelCase(title)}`]: title,
    [`page--is${capitalizeFirstLetter(pageType)}Page`]: pageType,
    [`page--is${capitalizeFirstLetter(windowSize.currentBreakpoint)}`]: true,
    'page--formIsDirty': flags.isFormDirty,
    'page--formIsFirstStep': flags.isFirstStep,
    'page--userHasInteractedWithForm':
      (!flags.isFirstStep && flags.isFormDirty) ||
      flags.isResumeSessionAcknowledged,
    'page--hasStickyHeader': flags.hasStickyHeader,
    'page--hasExpandedCard': flags.hasExpandedCard,
    'page--confirmationModalIsVisible': resumeModal.isOpen,
    'page--modalIsVisible': !!activeModalId,
    'page--navIsOpen': flags.navIsOpen,
    'page--hasFormSidebar': flags.hasFormSidebar,
    'page--isThankYou': flags.isThankyouPage,
  });

  return (
    <div
      id={microSiteTheme}
      data-testid="themeWrapper"
      className={pageClassName}
      style={cssCustomProps}
      ref={themeWrapperRef}
    >
      {children}
    </div>
  );
}

ThemeWrapper.propTypes = {
  microSiteTheme: PropType.string,
  title: PropType.string,
  children: PropType.node.isRequired,
};

export default ThemeWrapper;

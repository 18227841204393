import { getImageDataFromWpResponse } from 'utils/transformHelpers';

export function filterFaqsByTaxonomy(faqs, taxonomy) {
  const nextFaqs = faqs.filter((faq) => {
    // If the FAQ has no assigned taxonomies, include it
    if (
      Object.keys(faq.taxonomy).every((key) => faq.taxonomy[key].length === 0)
    ) {
      return true;
    }

    return Object.keys(faq.taxonomy).every((key) => {
      // Check if the FAQ has the taxonomy key and if it includes all terms
      return (
        faq.taxonomy[key].length === 0 ||
        faq.taxonomy[key].every((term) => taxonomy[key].includes(term))
      );
    });
  });

  return nextFaqs;
}

export default function wpToFaqPropsTransform(
  acfValues,
  { pageLayoutInfo: { taxonomy } }
) {
  const faqProps = {
    background: acfValues.background || '',
    defaultTitleSize: acfValues.titleTextSizeAdjustment || '',
    variation: acfValues.variation || '',
    isReversed: acfValues.isReversed || false,
    images: getImageDataFromWpResponse(acfValues.images),
    mobileImage: acfValues.images.mobileImage || {},
    content: acfValues.content || '',
    subTitle: acfValues.subTitle || '',
    title: acfValues.title || '',
    faqs: filterFaqsByTaxonomy(acfValues.faqs, taxonomy) || [],
    customCssClass: acfValues.customCssClass || '',
  };

  return faqProps;
}

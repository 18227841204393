import { FIELD_NAMES, QUERY_PARAMS } from 'consts';
import get from 'lodash/get';

const { DEGREE_GUID_PARAM, PARENT_CAT_GUID_PARAM, CATEGORY_GUID_PARAM } =
  QUERY_PARAMS;

const {
  CLICK_PORTAL_DEGREES,
  CLICK_PORTAL_CONCENTRATIONS,
  CLICK_PORTAL_SUBJECTS,
} = FIELD_NAMES;

/**
 * @summary We may not have label so need to fish for it
 */
export default function getFormSelectionObjects(
  formValues,
  microSiteTaxonomyMap
) {
  const degreeValue = get(
    formValues,
    [CLICK_PORTAL_DEGREES, 'value'],
    formValues[CLICK_PORTAL_DEGREES]
  );
  const categoryValue = get(
    formValues,
    [CLICK_PORTAL_CONCENTRATIONS, 'value'],
    formValues[CLICK_PORTAL_CONCENTRATIONS]
  );
  const subjectValue = get(
    formValues,
    [CLICK_PORTAL_SUBJECTS, 'value'],
    formValues[CLICK_PORTAL_SUBJECTS]
  );

  const degree = get(microSiteTaxonomyMap, 'degrees', []).find(
    ({ value }) => degreeValue === value
  );
  const subject = get(degree, 'children', []).find(
    ({ value }) => subjectValue === value
  );

  const category = get(subject, 'children', []).find(
    ({ value }) => categoryValue === value
  );

  // TODO: [T1-9136] degreeValue and other Value objects can be a string or Object, need to fix this!
  // if (get(degreeValue, 'value', degreeValue) && !degree) {
  //   LogError(`degreeValue "${degreeValue}" not found`, { formValues });
  // }
  // if (subjectValue && !subject) {
  //   LogError(`subjectValue "${subjectValue}" not found`, { formValues });
  // }
  // if (categoryValue && !category) {
  //   LogError(`categoryValue "${categoryValue}" not found`, { formValues });
  // }

  return {
    degree,
    subject,
    category,
  };
}

/**
 * @summary generate form object from query params
 * @param {Object|URLSearchParams} query - query params
 * @param {Object} microSiteTaxonomyMap - micro site taxonomy map
 */
export function getFormSelectionObjectsFromQuery(query, microSiteTaxonomyMap) {
  const currentSelection = {
    [CLICK_PORTAL_DEGREES]: {
      value: query.get
        ? query.get(DEGREE_GUID_PARAM)
        : query[DEGREE_GUID_PARAM],
    },
    [CLICK_PORTAL_CONCENTRATIONS]: {
      value: query.get
        ? query.get(CATEGORY_GUID_PARAM)
        : query[CATEGORY_GUID_PARAM],
    },
    [CLICK_PORTAL_SUBJECTS]: {
      value: query.get
        ? query.get(PARENT_CAT_GUID_PARAM)
        : query[PARENT_CAT_GUID_PARAM],
    },
  };

  const objectValues = getFormSelectionObjects(
    currentSelection,
    microSiteTaxonomyMap
  );

  return {
    [CLICK_PORTAL_DEGREES]: objectValues.degree,
    [CLICK_PORTAL_SUBJECTS]: objectValues.subject,
    [CLICK_PORTAL_CONCENTRATIONS]: objectValues.category,
  };
}

import { QUERY_PARAMS, QUERY_STRING, REGION_COOKIE_NAME } from 'consts';
import { LogError, LogInfo, LogWarning, newrelicPageAction } from './logging';
import { COUNTERS, incrementCounter } from './counters';
import isBrowser from './isBrowser';

export const TRIAD_USER_SESSION_ID = 'triad_user_session_id';
let TRIAD_PAGE_VIEW_ID;

const { SHARED_EDU_ID, SHARED_SESSION_ID, ORIGIN_SCHOOL_CODE } = QUERY_PARAMS;

const MEM_COOKIE_MAP = {};

/**
 * @summary when we are unable to set cookies on user device we use this to store in memory
 * @param {String} name - the name of the cookie
 * @param {String} value - the value of the cookie
 */
function setCookieInMemory(name, value) {
  MEM_COOKIE_MAP[name] = value;
  incrementCounter(COUNTERS.USING_MEM_COOKIE);
}

/**
 * @summary when we are unable to access cookies on user device we try to set/get them from memory
 * @param {String} name - the name of the cookie
 */
function getCookieFromMemory(name) {
  return MEM_COOKIE_MAP[name] || '';
}

/**
 * @param {String} name - the name of the cookie
 */
function deleteCookieFromMemory(name) {
  delete MEM_COOKIE_MAP[name];
}

/**
 * @summary use this to get a cookie
 * @param {String} name - the name of the cookie you want
 */
// TODO: Update function to match specific cookie name, not use fuzzy match
export function getCookie(name) {
  if (document.cookie.length > 0) {
    let startIndex = document.cookie.indexOf(`${name}=`);
    if (startIndex !== -1) {
      startIndex = startIndex + name.length + 1;
      let endIndex = document.cookie.indexOf(';', startIndex);
      if (endIndex === -1) {
        endIndex = document.cookie.length;
      }

      const unescapeCookieValue = unescape(
        document.cookie.substring(startIndex, endIndex)
      );
      const rawCookieValue = document.cookie.substring(startIndex, endIndex);

      // TODO: [T1-10281] follow up on removing the use of unescape
      // Adding a counter here to see if we can just removed the use of unescape
      if (rawCookieValue !== unescapeCookieValue) {
        incrementCounter(COUNTERS.UNESCAPE_COOKIE);
      }

      if (!unescapeCookieValue) {
        LogError('Found but failed to parse cookie value', {
          name,
          foundInMemory: Boolean(getCookieFromMemory(name)),
        });
      }

      return unescapeCookieValue;
    }
    return getCookieFromMemory(name);
  }

  return getCookieFromMemory(name);
}

/**
 * @summary create a cookie
 * @param {String} name - the name of the cookie
 * @param {String} value - the value of the cookie
 * @param {Number} days - how long to let this cookie live, leave blank for session cookie
 */
export function setCookie(name, value, days) {
  if (!value) {
    return;
  }

  try {
    let expires;
    if (days) {
      const date = new Date();
      // time is set in milliseconds that's why we multiply here
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires= + ${date.toGMTString()}`;
    } else {
      expires = '';
    }
    document.cookie = `${name}=${value}${expires}; path=/`;

    if (!getCookie(name)) {
      throw new Error('Cookie was set but not found');
    }
  } catch (error) {
    LogWarning('Failed to set cookie', {
      name,
      value,
      error: error.message,
      cookieEnabled: window.navigator?.cookieEnabled, // No IE support
    });
    setCookieInMemory(name, value);
  }
}

/**
 * @summary use this to delete a cookie
 * @param {String} name - the name of the cookie you want to delete
 */
export function deleteCookie(name) {
  if (getCookie(name)) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  } else {
    document.cookie = `${QUERY_STRING}${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    deleteCookieFromMemory(name);
  }
}

/**
 * @summary get a map of all cookies on browser
 */
export function getAllCookies() {
  const cookieMap = document.cookie
    .split(';')
    .map((c) => c.trim().split('='))
    .reduce((map, c) => ({ ...map, [c[0]]: c[1] }), MEM_COOKIE_MAP);

  return {
    map: cookieMap,
    keys: Object.keys(cookieMap),
    values: Object.values(cookieMap),
  };
}

/**
 * @summary used to get user's session ID
 */
export function getUserSessionId() {
  if (!isBrowser()) {
    return '';
  }

  const userSessionId = getCookie(TRIAD_USER_SESSION_ID);

  if (userSessionId === 'undefined') {
    LogError('SessionId Cookie was unexpectedly set to undefined');
    deleteCookie(TRIAD_USER_SESSION_ID);
  }

  return userSessionId;
}

/**
 * @summary used to set user's session ID
 */
export function setUserSessionId(sessionId) {
  if (!sessionId) {
    LogWarning('setUserSessionId called with no user Session Id');
  } else {
    setCookie(TRIAD_USER_SESSION_ID, sessionId);
  }
}

/**
 * @summary used to get Page View ID
 */
export function getPageViewId() {
  return TRIAD_PAGE_VIEW_ID;
}

/**
 * @summary used to set Page View ID
 */
export function setPageViewId(pageViewId) {
  if (!pageViewId) {
    LogError('Page View Id Not Set');
  } else {
    TRIAD_PAGE_VIEW_ID = pageViewId;
  }
}

/**
 * @summary used to get user's session ID
 */
export function getStoredQueryParams() {
  const cookies = getAllCookies();
  const savedQueryParamsMap = {};

  cookies.keys.forEach((key) => {
    if (key.includes(QUERY_STRING)) {
      const originalKey = key.replace(QUERY_STRING, '');
      savedQueryParamsMap[originalKey] = cookies.map[key];
    }
  });

  const requestArr = Object.keys(savedQueryParamsMap).map((key) => ({
    name: key,
    value: savedQueryParamsMap[key],
  }));

  return {
    queryParamMap: savedQueryParamsMap,
    requestArray: requestArr,
  };
}

/**
 * @summary used to set user's session ID. We do this to persist these values as user browses the site.
 */
export function setStoredQueryParams(queryParams) {
  Object.keys(queryParams).forEach((key) =>
    setCookie(`${QUERY_STRING}${key}`, queryParams[key])
  );

  // If user has session id or other private params from a click portal redirect we need to clear it out
  if (
    queryParams[SHARED_EDU_ID] ||
    queryParams[SHARED_SESSION_ID] ||
    queryParams[ORIGIN_SCHOOL_CODE]
  ) {
    const _queryParams = { ...queryParams };
    delete _queryParams[SHARED_EDU_ID];
    delete _queryParams[SHARED_SESSION_ID];
    delete _queryParams[ORIGIN_SCHOOL_CODE];
    const params = Object.keys(_queryParams)
      .map((key) => `${key}=${_queryParams[key]}`)
      .join('&');
    window.history.replaceState({}, '', `?${params}`);
  }
}

/**
 * @summary use this to load an iFrame on the site
 */
export function loadIframe(src) {
  const iframe = document.createElement('iframe');
  iframe.width = '0px';
  iframe.height = '0px';
  iframe.title = 'Compare Top Schools User Tracking';
  iframe.style.position = 'absolute';
  iframe.setAttribute('src', src);

  document.body.appendChild(iframe);
}

/**
 * @summary use this to get the url for the user stitching across all our mico sites
 */
export function getUserStitchingUrl(sessionId, queryParams) {
  const baseUrl = 'https://www.comparetopschools.com/usertracking.aspx';
  const tmsurl = `${window.location.pathname}${window.location.search}`;
  const tmshost = window.location.host;

  let fullUrl = `${baseUrl}?userVisitGuid=${sessionId}&tmsurl=${tmsurl}&tmshost=${tmshost}&tmsaq=1`;
  if (queryParams) {
    Object.keys(queryParams).forEach((param) => {
      fullUrl += `&${param}=${queryParams[param]}`;
    });
  }

  return fullUrl;
}

/**
 * @summary IMPORTANT: never run this function on data that does not come back from our backend systems. If that data comes from user input DONT RUN IT. this is meant to be used only for support of legacy pixels we have in our Triadsystem
 * @param {String} rawPixelData any raw html data that we need to process on the browser
 */
export function appendAndEvalRawHtml(rawPixelData) {
  const containerId = 'global-pixel-container';
  const parser = new DOMParser();
  const parsedDocument = parser.parseFromString(rawPixelData, 'text/html');

  const pixelContainer =
    document.getElementById(containerId) || document.createElement('div');
  pixelContainer.id = containerId;

  [...parsedDocument.getElementsByTagName('script')].forEach((scriptTag) => {
    // We should NEVER use eval, but in this case we need to support legacy code that returns raw HTML that has scripts in it.
    // seeing as this data only comes from our backend system it can be trusted. Note that this function should NEVER be used for data
    // that is not coming from our backend as it can pose a serious security risk
    try {
      if (!scriptTag.src) {
        // eslint-disable-next-line no-eval
        eval(scriptTag.innerHTML);
        pixelContainer.append(scriptTag);
      } else {
        const cloneScript = document.createElement('script');
        Object.values(scriptTag.attributes).forEach((attr) => {
          cloneScript[attr.name] = attr.value;
        });
        pixelContainer.append(cloneScript);
      }
    } catch (error) {
      LogError(`Pixel Tracking Eval Failed : ${error.message}`);
    }
  });

  // [...parsedDocument.getElementsByTagName('noscript')].forEach((scriptTag) => {
  //   scriptTag.parentNode.removeChild(scriptTag);
  // });

  [...parsedDocument.getElementsByTagName('iframe')].forEach((scriptTag) => {
    pixelContainer.append(scriptTag);
  });

  [...parsedDocument.getElementsByTagName('img')].forEach((scriptTag) => {
    pixelContainer.append(scriptTag);
  });

  // Insert remaining content
  if (!document.getElementById(containerId)) {
    document.body.appendChild(pixelContainer);
  }
}

/**
 * @summary use this to get the origin school code from the url or storied query params
 */
export function getOriginSchoolCode() {
  try {
    const urlQueryParams = new URLSearchParams(window.location.search);
    if (urlQueryParams.has(QUERY_PARAMS.ORIGIN_SCHOOL_CODE)) {
      return urlQueryParams.get(QUERY_PARAMS.ORIGIN_SCHOOL_CODE);
    }

    // due to navigation or page refresh. Note we remove ORIGIN_SCHOOL_CODE from the url on page load
    const storedQueryParams = getStoredQueryParams().queryParamMap;
    if (storedQueryParams[QUERY_PARAMS.ORIGIN_SCHOOL_CODE]) {
      return storedQueryParams[QUERY_PARAMS.ORIGIN_SCHOOL_CODE];
    }
  } catch (error) {
    return null;
  }

  return null;
}

/**
 * @summary use this to get the user's region from a cookie
 */
export function getUserRegionCookie() {
  return getCookie(REGION_COOKIE_NAME);
}

/**
 * Retrieves the value of the 'sid' query parameter or the 'sid' cookie.
 *
 * @return {string} The value of the 'sid' query parameter or the 'sid' cookie.
 *                 If neither is present, an empty string is returned.
 */
export function getSidCookieOrQueryParams() {
  const { queryParamMap } = getStoredQueryParams();
  if (queryParamMap.sid) {
    return queryParamMap.sid;
  }

  try {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get('sid');
  } catch (error) {
    return '';
  }
}

export function logNewRelicUniqueUser() {
  if (!isBrowser()) {
    return;
  }

  const userTrackingInterval = setInterval(() => {
    try {
      if (window.newrelic) {
        clearInterval(userTrackingInterval);
        if (getCookie('triad_unique_user_tracked') !== 'true') {
          setCookie('triad_unique_user_tracked', 'true', 365);
          LogInfo('Page View', { description: 'User First Visit' });
          newrelicPageAction('User First Visit');
        } else {
          LogInfo('Page View', { description: 'Returning User' });
        }
      }
    } catch (error) {
      LogError('LogNewRelicUniqueUser', error);
    }
  }, 1000);
}

import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import { BREAKPOINT_MAP } from 'consts';

function getCurrentBreakpoint(width) {
  let currentBreakpoint = '';

  if (width <= BREAKPOINT_MAP.MOBILE.width) {
    currentBreakpoint = BREAKPOINT_MAP.MOBILE.label;
  }

  if (
    width > BREAKPOINT_MAP.MOBILE.width &&
    width <= BREAKPOINT_MAP.TABLET.width
  ) {
    currentBreakpoint = BREAKPOINT_MAP.TABLET.label;
  }

  if (width > BREAKPOINT_MAP.TABLET.width) {
    currentBreakpoint = BREAKPOINT_MAP.DESKTOP.label;
  }

  return currentBreakpoint;
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    currentBreakpoint: undefined,
  });

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        currentBreakpoint: getCurrentBreakpoint(window.innerWidth),
      });
    }, 200);

    window.addEventListener('resize', handleResize);

    // run on page load since we don't have access window object on the server
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;

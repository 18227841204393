/* eslint-disable import/prefer-default-export */
import { QUESTION_IDS } from 'consts';

export function mockMPQuestionOptions() {
  return Promise.resolve({
    questions: [
      {
        schoolCode: 'microportal',
        questionId: 45,
        options: [
          {
            group: '',
            options: [
              {
                label: 'TEST SCHOOL 1',
                value: '1',
                isSelected: true,
              },
              {
                label: 'TEST SCHOOL 2',
                value: '2',
                isSelected: true,
              },
              {
                label: 'TEST SCHOOL 3',
                value: '3',
                isSelected: true,
              },
            ],
          },
        ],
      },
      {
        schoolCode: 'microportal',
        questionId: 2,
        options: [
          {
            group: '',
            options: [
              {
                label: 'TEST:Business',
                value: 'BUSINESS',
                guid: 'cfc9f419-8001-4ed2-a91e-98a82df6cc21',
              },
              {
                label: 'TEST:Health & Medical',
                value: 'HEALTHCARE',
                guid: '586bd381-538d-4805-aa9a-d4bd37b6d954',
              },
              {
                label: 'TEST:Psychology & Counseling',
                value: 'PSYCHOLOGY',
                guid: '0b3d7cc9-b9ee-46f3-9fb5-b2e38f329dd3',
              },
              {
                label: 'TEST:Education',
                value: 'EDUCATION',
                guid: '5ab20c20-b860-48e3-ab01-14c959bee431',
              },
              {
                label: 'TEST:Criminal Justice & Law',
                value: 'CRIM_LAW',
                guid: '46f32468-c94c-4dce-bd10-a3308b6fddda',
              },
              {
                label: 'TEST:Computers & Technology',
                value: 'TECHNOLOGY',
                guid: '67a5fac9-d70d-4abc-97be-45dddaca266b',
              },
              {
                label: 'TEST:Art & Design',
                value: 'ART_DESIGN',
                guid: 'c8ec4da1-3c36-444e-b32c-1b863d3af54a',
              },
              {
                label: 'TEST:Liberal Arts & Humanities',
                value: 'LIBERAL',
                guid: '1e50ccb7-22ba-4383-9ff4-f2a545786674',
              },
              {
                label: 'TEST:Science & Engineering',
                value: 'SCIENCE_ENG',
                guid: '6fbb27fc-81f9-4dd4-85fb-d7639cf24deb',
              },
              {
                label: 'TEST:Trade & Technical',
                value: 'TRADE_TECH',
                guid: '9487e4d1-e432-4a0a-8caa-c37d242609f2',
              },
            ],
          },
        ],
      },
      {
        schoolCode: 'microportal',
        questionId: 14,
        options: [
          {
            group: '',
            options: [
              {
                label: 'Some Range',
                value: '1',
                isSelected: true,
              },
              {
                label: 'Other Range',
                value: '2',
                isSelected: true,
              },
            ],
          },
        ],
      },
    ],
    trace: '11526705-7a3d-46df-b1a5-fa43c0b92967',
  });
}

export function mockGetMPAdditionalSchoolListings() {
  return Promise.resolve(
    [1, 2, 3, 4].map((num) => ({
      value: num,
      schoolCode: num,
      label: `school ${num}`,
      rating: 4,
      schoolName: `Test School ${num + 1}`,
      schoolDesc: [`School Number ${num + 1} has some description here`],
      programOptions: [
        {
          options: [
            {
              value: 'afdacd1d-7a88-4ae4-968b-04e67a90a9c4',
              label: 'Test Program 1',
            },
            {
              value: 'bfdacd1d-7a88-4ae4-968b-04e67a90a9c4',
              label: 'Test Program 2',
            },
          ],
        },
      ],
    }))
  );
}

export function mockGetMPTcpaListings(payload) {
  const phoneNumber = payload.body.questionReplies.find(
    ({ questionId }) => questionId === QUESTION_IDS.PRIMARY_PHONE
  ).questionAnswer[0];

  return Promise.resolve({
    autoSelectedSchools: [
      {
        schoolImpressionGuid: 'abc',
        schoolName: 'Fake School 1',
      },
      {
        schoolImpressionGuid: 'xyz',
        schoolName: 'Fake School 2',
      },
    ],
    remainingSchoolsCount: phoneNumber[phoneNumber.length - 1], // last digit of phone number will be mock value,
  });
}

export function mockGetMPSchoolListings() {
  return Promise.resolve({
    Listings: [
      { value: '1', label: 'Fake School 1' },
      { value: '2', label: 'Fake School 2' },
      { value: '3', label: 'Fake School 3' },
      { value: '4', label: 'Fake School 4' },
    ],
  });
}

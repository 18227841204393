/**
 * @summary This is the main entry point for all server side rendered components.
 *  Being that this is the parent of all component we can have all common functionality
 *  here. Please note that at this level we are unable to make server-side API call however.
 */
// polyfills for older browsers
import 'array-flat-polyfill';
import 'intersection-observer';
import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isBrowser from 'utils/isBrowser';
import ThemeWrapper from 'themes/ThemeWrapper';
import ConfirmationModal from 'components/base/modals/confirmation-modal/ConfirmationModal';
import SessionInitializer from 'components/global/SessionInitializer';
import {
  FormSettingsPropTypes,
  QuestionnairePropTypes,
  QuestionnairesPropTypes,
} from 'utils/propTypes';
import {
  getActions,
  initialState,
  reducer,
} from 'hooks/reducers/GlobalReducer';
import GlobalContext from 'hooks/contexts/GlobalContext';
import useWindowSize from 'hooks/custom/useWindowSize';
import { InitializeLogging } from 'utils/logging';
import ModalContainer from 'components/base/modals/ModalContainer';
import { DynamicDevContainer } from 'components/DynamicComponents';
import { isSessionStorageEnabled } from 'utils/generalUtils';
import { COUNTERS, incrementCounter } from 'utils/counters';
import {
  getStoredQueryParams,
  logNewRelicUniqueUser,
} from 'utils/analyticsHelpers';
import configs from '../configs';

if (process.env.NODE_ENV === 'development') {
  try {
    // eslint-disable-next-line global-require, import/no-unresolved
    require('themes/dev.scss');
  } catch (error) {
    console.error('themes/dev.scss not found');
  }
}

// Server-side Init of tracking
if (!isBrowser()) {
  InitializeLogging('7aadb60ffc0a4b89ad162c4b7c4680d6', configs);
}

export default function GlobalAppContainer({
  Component,
  // this comes from a pages getInitialProps, so always strip out a questionnaire is its there and place it in context
  pageProps: {
    questionnaire,
    questionnaires,
    taxonomyValues,
    formSettings,
    microSiteTaxonomyMap,
    ...pageProps
  },
  ...rest
}) {
  // TODO: [T1-10479] Remove all pageProps from reducer state. We should only be using the global context to pass data to components
  const initialReducerState = {
    ...initialState,
    microSiteTaxonomyMap,
    clickPortal: {
      ...initialState.clickPortal,
      ...get(pageProps, 'schoolListingResults', {}),
    },
  };

  const [state, dispatch] = useReducer(reducer, initialReducerState);

  const windowSize = useWindowSize();
  const siteMeta = pageProps.meta || {};

  const reducerActions = getActions(dispatch, {
    siteMeta,
    taxonomyValues,
    microSiteTaxonomyMap,
  });

  // On client side routing to a click portal page we get updated page props from the server
  useEffect(() => {
    if (pageProps.schoolListingResults) {
      reducerActions.updateClickPortalData(dispatch, {
        ...state.clickPortal,
        ...pageProps.schoolListingResults,
      });
    }
  }, [pageProps.schoolListingResults]);

  useEffect(() => {
    const { isDraftPage = false, isDraftMode } = siteMeta;

    logNewRelicUniqueUser();

    // TODO: [T1-10282] follow up on this. We may be able to leverage session storage as a fallback if cookies are disabled
    if (!isSessionStorageEnabled()) {
      incrementCounter(COUNTERS.SESSION_STORAGE_DISABLED);
    }

    /**
     *  TODO: [T1-10489] We need to upgrade to the latest version of nextjs to use middleware
     *  which would be the best place to do this. This client side solution will cause a flash of content
     */
    // Check if in preview mode by looking for a specific cookie or URL query parameter
    const isPreviewMode =
      getStoredQueryParams()?.queryParamMap?.preview === 'true' ||
      rest.router.query?.preview === 'true';

    // If in draft mode, not a draft page, and not in preview mode, then redirect
    if (isDraftMode && !isDraftPage && !isPreviewMode) {
      rest.router.push('/under-construction');
    }
    // No else or return needed here since we only want to redirect under the specific condition above
  }, [siteMeta, rest.router.query]);

  // avoid initializing any TLG apis like GetNewSession if we don't have siteMeta from WP.
  if (!siteMeta) {
    return null;
  }

  return (
    <GlobalContext.Provider
      value={{
        ...state,
        actions: reducerActions,
        questionnaire,
        formSettings,
        questionnaires,
        taxonomyValues,
        windowSize,
        siteMeta,
        microSiteTaxonomyMap,
      }}
    >
      <SessionInitializer
        configEnv={pageProps.env?.configEnv}
        environmentVariables={pageProps.env}
        {...{
          questionnaire,
          updateUserData: reducerActions.updateUserData,
          query: rest.router.query,
          siteMeta,
        }}
      />
      <ThemeWrapper
        microSiteTheme={get(pageProps, 'meta.theme')}
        title={pageProps.title}
      >
        <ConfirmationModal {...state.resumeModal} />
        <Component {...rest} pageProps={pageProps} />
        <ModalContainer />
      </ThemeWrapper>
      {pageProps.env?.configEnv === 'development' && (
        <DynamicDevContainer env={pageProps.env} />
      )}
    </GlobalContext.Provider>
  );
}

GlobalAppContainer.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({
    title: PropTypes.string,
    meta: PropTypes.shape({}),
    questionnaires: QuestionnairesPropTypes,
    questionnaire: QuestionnairePropTypes,
    taxonomyValues: PropTypes.shape({
      degrees: PropTypes.arrayOf(PropTypes.string),
      parentCategories: PropTypes.arrayOf(PropTypes.string),
      categories: PropTypes.arrayOf(PropTypes.string),
    }),
    formSettings: FormSettingsPropTypes,
    microSiteTaxonomyMap: PropTypes.shape({}),
    schoolListingResults: PropTypes.shape({}),
  }),
};

import { FIELD_TYPES } from 'components/AVAILABLE_COMPONENTS';
import PropTypes from 'prop-types';

export const numOrStrPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const OptionPropType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
});

export const OptionsPropType = PropTypes.arrayOf(OptionPropType);

export const GroupedOptionPropType = PropTypes.shape({
  group: PropTypes.string.isRequired,
  options: OptionPropType,
});

export const GroupedOptionsPropType = PropTypes.arrayOf(GroupedOptionPropType);

export const pagePropsObj = {
  meta: PropTypes.shape({
    siteName: PropTypes.string.isRequired,
    siteDescription: PropTypes.string.isRequired,
    robots: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    googleTagManagerSiteId: PropTypes.string.isRequired,
    theme: PropTypes.string,
    schoolCode: PropTypes.string,
    canonicalDomain: PropTypes.string,
    ketch: PropTypes.shape({
      isEnabled: PropTypes.bool,
      propertyId: PropTypes.string,
    }),
  }),
  header: PropTypes.shape({
    headerComponent: PropTypes.string.isRequired,
    menuItems: PropTypes.array,
  }),
  footer: PropTypes.shape({
    component: PropTypes.string,
  }),
  env: PropTypes.object,
  title: PropTypes.string,
};

export const pagePropsType = PropTypes.shape(pagePropsObj);

export const QuestionPropType = PropTypes.shape({
  id: numOrStrPropType,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(FIELD_TYPES)),
  required: PropTypes.bool,
  options: OptionsPropType,
  isPii: PropTypes.bool,
});

export const QuestionsPropType = PropTypes.arrayOf(QuestionPropType);

export const StepPropType = PropTypes.shape({
  id: numOrStrPropType,
  questions: QuestionsPropType,
  progressMeter: numOrStrPropType,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  heading: PropTypes.string,
});

export const StepsPropType = PropTypes.arrayOf(StepPropType);

export const ProgramPropType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool,
  isFeatured: PropTypes.bool,
  accreditation: PropTypes.string,
  nextStartDate: PropTypes.string,
  slug: PropTypes.string,
});

export const ProgramsPropType = PropTypes.arrayOf(ProgramPropType);

export const ImageObjPropType = PropTypes.shape({
  url: PropTypes.string,
  alt: PropTypes.string,
  mimeType: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
    PropTypes.string,
  ]),
  sizes: PropTypes.objectOf(
    PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      url: PropTypes.string,
    })
  ),
});

export const WPImagesPropType = PropTypes.objectOf(ImageObjPropType);

export const CtaPropTypes = PropTypes.shape({
  useCTA: PropTypes.bool,
  buttonText: PropTypes.string,
  link: PropTypes.string,
  buttonActionType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
});

const navItemsShape = {
  level: PropTypes.number,
  id: PropTypes.string,
  label: PropTypes.string,
};

export const NavItemProps = PropTypes.shape({
  ...navItemsShape,
  children: PropTypes.arrayOf(PropTypes.shape(navItemsShape)),
});

export const SlidePropTypes = PropTypes.shape({
  content: PropTypes.string,
  cta: CtaPropTypes,
  images: WPImagesPropType,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  videoEmbedId: PropTypes.string,
});

export const SlidesPropTypes = PropTypes.arrayOf(SlidePropTypes);

export const QuestionnairePropTypes = PropTypes.shape({
  component: PropTypes.string,
  id: numOrStrPropType,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  steps: StepsPropType,
});

export const QuestionnairesPropTypes = PropTypes.oneOfType([
  PropTypes.shape({
    default: QuestionnairePropTypes,
    fullPage: QuestionnairePropTypes,
  }),
  PropTypes.bool,
]);

export const FormSettingsPropTypes = PropTypes.shape({
  questionnaires: QuestionnairesPropTypes,
  formTitle: PropTypes.string,
  success: PropTypes.shape({
    text: PropTypes.string,
    cta: CtaPropTypes,
  }),
});

export const FooterLinksPropTypes = PropTypes.arrayOf(
  PropTypes.shape({ label: PropTypes.string, link: PropTypes.string })
);

export const FontAdjustmentPropTypes = PropTypes.shape({
  mobile: PropTypes.string,
  tablet: PropTypes.string,
  desktop: PropTypes.string,
});

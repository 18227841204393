import { getImageDataFromWpResponse, parseCTA } from 'utils/transformHelpers';

export function parseStats({ showStats, statsList }) {
  return {
    showStats: showStats || false,
    statsList: statsList || [],
  };
}
export default function wpToBillBoardTransform(acfValues) {
  return {
    images: getImageDataFromWpResponse(acfValues.images),
    title: acfValues.title || '',
    subTitle: acfValues.subTitle || '',
    content: acfValues.content || '',
    cta: parseCTA(acfValues.callToAction),
    imageAlignment: acfValues.imageAlignment || '',
    customCssClass: acfValues.customCssClass || '',
    variation: acfValues.variation || '',
    background: acfValues.background || '',
    isReversed: acfValues.isReversed || false,
    mediaWidth: acfValues.mediaWidth || '',
    mediaType: acfValues.mediaType || 'image',
    stats: parseStats(acfValues.stats || {}),
    wysiwyg: acfValues.wysiwyg || '',
    videoEmbedId: acfValues.videoEmbedId || '',
  };
}

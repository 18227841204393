import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useOutsideClick from 'hooks/custom/useOutsideClick';
import { MODAL_INTERACTION_TYPES } from 'utils/trackingFunctions';

const { ACCEPT, CLOSE, CANCEL } = MODAL_INTERACTION_TYPES;

export default function ConfirmationModal({
  isOpen = false,
  body = '',
  title = '',
  onCancel,
  onSubmit,
}) {
  const modalRef = useRef();

  const _onSubmit = () => {
    onSubmit && onSubmit({ interaction: ACCEPT });
  };

  const _onCancel = () => {
    onCancel && onCancel({ interaction: CANCEL });
  };

  const _onClickAway = () => {
    onCancel && onCancel({ interaction: CLOSE });
  };

  useOutsideClick(modalRef, _onClickAway);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.documentElement.style.overflow = isOpen ? 'hidden' : null;
  }, [isOpen]);

  if (isOpen) {
    return (
      <>
        <div className="confirmationModal" ref={modalRef}>
          <button
            type="button"
            className="confirmationModal__closeBtn"
            onClick={_onClickAway}
          >
            <span className="isVisuallyHidden">Close</span>
          </button>
          <div className="confirmationModal__title">{title}</div>
          <div className="confirmationModal__body">{body}</div>
          <div className="confirmationModal__buttonContainer">
            <button
              onClick={_onSubmit}
              type="button"
              className="confirmationModal__button"
            >
              Continue
            </button>
          </div>
          <div className="confirmationModal__footer">
            <button
              onClick={_onCancel}
              className="confirmationModal__skipButton"
              type="button"
            >
              Skip
            </button>
          </div>
        </div>
        <div className="confirmationModal__overlay" />
      </>
    );
  }
  return null;
}
ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  body: PropTypes.string,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};
